import React from "react";
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
const ScoreChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <div>Loading...</div>; // Display a loading indicator when data is not yet available
  }

  const sortedData = [...data].sort(
    (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
  );
  const sum = sortedData.reduce((acc, next) => acc + next.score, 0);
  const average = sum / sortedData.length;
  sortedData.forEach((data) => {
    data.average = average;
  });
  // Infer the start and end date from the data
  const endDate = new Date(sortedData[sortedData.length - 1].updated_at);
  const startDate = new Date(sortedData[0].updated_at);
  startDate.setDate(
    startDate.getDate() - (15 - Math.min(15, sortedData.length))
  ); // Adjust based on available data

  // Fill in missing days with zeros
  let currentDate = new Date(startDate);
  const fullStats = [];
  while (currentDate <= endDate) {
    const dateString = currentDate.toISOString().split("T")[0];
    const existingStat = sortedData.find(
      (stat) => stat.updated_at === dateString
    );
    if (existingStat) {
      fullStats.push(existingStat);
    } else {
      fullStats.push({
        fid: 23039,
        score: 0,
        likes: 0,
        recasts: 0,
        daily: true,
        comments: 0,
        casts: 0,
        replies: 0,
        updated_at: dateString,
      });
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Calculate the moving average for the 'score' (as an example)
  const movingAverage = fullStats.map((_, index, arr) => {
    const start = Math.max(0, index - 14);
    const end = index + 1;
    const range = arr.slice(start, end);
    const average =
      range.reduce((acc, curr) => acc + curr.score, 0) / range.length;
    return { ...arr[index], movingAverageScore: average };
  });

  const formatDate = (tickItem) => {
    const date = new Date(tickItem);
    return date.toLocaleDateString("en-US", { month: "short", day: "numeric" });
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date = new Date(label).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      return (
        <div
          style={{
            backgroundColor: "#ffffffaa",
            color: "black",
            padding: "5px",
            fontSize: "12px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p>{`${date}`}</p>
          <p>{`Score: ${payload[0].payload.score.toFixed(2)}`}</p>
          <p>{`Likes: ${payload[0].payload.likes}`}</p>
          <p>{`Comments: ${payload[0].payload.comments}`}</p>
          <p>{`Replies: ${payload[0].payload.replies}`}</p>
          <p>{`Recasts: ${payload[0].payload.recasts}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer className="pb-10" width="100%" height="100%">
      <AreaChart
        data={movingAverage}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="updated_at" tickFormatter={formatDate} />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Area type="monotone" dataKey="score" stroke="#fedd00" fill="#fedd00" />
        <Area
          dataKey="movingAverageScore"
          fill="transparent"
          stroke="red"
          strokeDasharray="3,3"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
export default ScoreChart;
