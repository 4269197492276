import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Landing() {
  const [fid, setFid] = useState(""); // State to hold the fid input
  const navigate = useNavigate(); // Hook to navigate programmatically

  // Function to handle changes to the input box
  const handleInputChange = (e) => {
    const value = e.target.value;
    setFid(value);
  };

  // Function to handle the GO button click
  const handleGoClick = () => {
    // Navigate to the /:fid path
    navigate(`/stats/${fid}`);
  };

  return (
    <div className="min-h-screen bg-[#17121c] flex flex-col items-center pt-20">
      <div className="text-5xl font-bold text-white mb-3">FARSTATS</div>
      <h3 className="text-xl text-white mb-10">
        Keep track of your accounts health
      </h3>
      <div className="flex items-center">
        <input
          type="text"
          placeholder="Enter FID or Username"
          className="p-2 rounded-md outline-none w-[300px] focus:ring-2 focus:ring-blue-500"
          value={fid} // Controlled input
          onChange={handleInputChange} // Update fid state on change
        />
        <button
          className="ml-5 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={handleGoClick} // Navigate on click
        >
          GO
        </button>
      </div>
    </div>
  );
}

export default Landing;
