import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useParams } from "react-router-dom";
import { makeApiUrl } from "../utils/common";


export default function FollowToAccess(props) {
  return (
      <div className="mx-auto text-center p-12">
        <a
          target="_blank"
          href={`https://warpcast.com/0mbre`}
          className=" bg-[#5837ba] cursor-pointer hover:bg-[#6a45d8] rounded-sm py-4 px-10 text-white text-center"
        >
         Follow @0mbre on Farcaster to access 
        </a>
    </div>
  )
}