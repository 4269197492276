export function makeApiUrl(path) {
  if (process.env.NODE_ENV === "development") {
    return `http://localhost:3002/api/${path}`
  }
  return `/api/${path}`
}


export function imgurSafe(url)  {
  if (url.includes('imgur')) {
    url = `https://res.cloudinary.com/merkle-manufactory/image/fetch/c_fill,w_500/${encodeURIComponent(url)}`
  }
  return url;
}