import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useParams, useNavigate } from "react-router-dom";
import { makeApiUrl } from "../utils/common";
import ScoreChart from "./ScoreChart";
import Summary from "./Summary";
import Followers from "./Followers";
import Ghosting from "./Ghosting";
import Unfollowers from "./Unfollowers";
import Fans from "./Fans";
import Favorites from "./Favorites";
import Recommended from "./Recommended";
import NonReciprocated from "./NonReciprocated";
import Following from "./Following";
import FollowToAccess from "./FollowToAccess";

const tabs = [
  { name: "Followers", Component: Followers },
  { name: "Following", Component: Following },
  { name: "Fans", Component: Fans },
  { name: "Favorites", Component: Favorites },
  { name: "Recommended", Component: Recommended },
  { name: "Ghost you", Component: Ghosting },
  // { name: "Non Reciprocated", Component: NonReciprocated },
  { name: "Unfollowers", Component: Unfollowers },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Stats() {
  const [scoreHistory, setScoreHistory] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [isFollowing, setIsFollowing] = useState(false);
  const [currentTab, setCurrentTab] = useState(tabs[0].name);
  const { fid } = useParams();
  const [newFid, setFid] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFid(value);
  };

  const handleGoClick = () => {
    if (newFid) {
      navigate(`/stats/${newFid}`);
    }
  };

  const requestURL = makeApiUrl(`stats/${fid}`);

  const CurrentTabComponent = tabs.find(
    (tab) => tab.name === currentTab
  )?.Component;

  useEffect(() => {
    const fetchScoreHistory = async () => {
      try {
        const response = await fetch(requestURL);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setScoreHistory(data.stats);
        setUserProfile(data.profile);
      } catch (error) {
        console.error("Failed to fetch score history:", error);
      }
    };
    fetchScoreHistory();
  }, [fid]);

  useEffect(() => {
    const checkFollowing = async () => {
      try {
        const response = await fetch(makeApiUrl(`is-following/${fid}`));
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        if (data && data.following) {
          setIsFollowing(true);
        }
      } catch (error) {
        console.error("Failed to fetch score history:", error);
      }
    };
    checkFollowing();
  }, [fid]);

  return (
    <div className="bg-[#17121c] min-h-screen flex flex-col items-center relative">
      <div className="relative overflow-hidden  bg-transparent">
        <a
          target="_blank"
          href={`https://warpcast.com/0mbre`}
          class="w-36 h-36 bg-[#5837ba] z-50 text-xs cursor-pointer hover:bg-[#6a45d8] rounded-full pl-0 pt-2 text-white text-center fixed -bottom-20 -right-5"
        >
          🎩 <br /> $Degen tips <br /> appreciated
        </a>
      </div>
      {/* Adjusted the max width to max-w-6xl for a larger container */}
      <div className="w-full max-w-6xl p-5 ">
        <div className=" text-white p-4 ap-4 mb-4 rounded-md flex justify-between flex-row">
          <div className="text-xl">Farstats</div>
          <div>
            <input
              type="text"
              placeholder={fid}
              className="p-2 rounded-md bg-[#392d46] outline-none focus:ring-2 focus:ring-blue-500"
              value={newFid}
              onChange={handleInputChange}
            />
            <button
              className="ml-5 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={handleGoClick} // Navigate on click
            >
              GO
            </button>
          </div>
        </div>
        <div className=" text-white p-4 ap-4 mb-3 rounded-md flex justify-between flex-row">
          <div className="flex items-center">
            <div>
              <img
                className="inline-block h-20 w-20 rounded-full"
                src={userProfile.pfpURL}
                alt=""
              />
            </div>
            <div className="ml-3">
              <p className="text-md font-md text-gray-700 group-hover:text-gray-900">
                <a
                  target="_blank"
                  href={`https://warpcast.com/${userProfile.username}`}
                  className="text-indigo-400 hover:text-indigo-300"
                >
                  @{userProfile.username}
                </a>
              </p>

              <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                FID: {userProfile.fid}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-4 mb-8">
          {/* Section 1 with an approximation of 65% width */}
          <div className="w-full md:w-2/3 bg-[#ffffff09] text-white p-4 h-[400px] rounded-md">
            <p className="text-sm font-medium text-gray-500 mb-5 text-center">
              Engagement score (past 15 days)
            </p>
            <ScoreChart data={scoreHistory} />
          </div>

          {/* Section 2 with an approximation of 35% width */}
          <div className="w-full md:w-1/3 bg-[#ffffff09] text-white p-4 rounded-md">
            <Summary data={scoreHistory} />
          </div>
        </div>

        {/* Section 3 */}

        <div className="bg-[#ffffff09] text-white p-4 rounded-md">
          <div className="mx-auto max-w-7xl">
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                onChange={(e) => {
                  e.preventDefault(); // Prevent the link from navigating
                  let value = e.target.value
                  setCurrentTab(value); // Update the current tab
                }}
                className="block w-full rounded-md border-none bg-white/5 py-2 pl-3 pr-10 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm"
                defaultValue={tabs[0].name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav className="flex border-b border-white/10 py-4">
                <ul
                  role="list"
                  className="flex min-w-full flex-none gap-x-6 px-2 text-sm font-semibold leading-6 text-gray-400"
                >
                  {tabs.map((tab) => (
                    <li key={tab.name}>
                      <a
                        href={tab.href}
                        onClick={(e) => {
                          e.preventDefault(); // Prevent the link from navigating
                          setCurrentTab(tab.name); // Update the current tab
                        }}
                        className={tab.current ? "text-indigo-400" : ""}
                      >
                        {tab.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
          <div>
            {isFollowing ? (
              CurrentTabComponent ? (
                <CurrentTabComponent isFollowing={isFollowing} />
              ) : null
            ) : (
              <FollowToAccess />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
