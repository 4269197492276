import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useParams } from "react-router-dom";
import { makeApiUrl } from "../utils/common";


export default function Summary(props) {
  const [followerCount, setFollowerCount] = useState([]);
  const { fid } = useParams();

  useEffect(() => {
    const fetchFollowers = async () => {
      try {
        const requestURL = makeApiUrl(`follower-count/${fid}`);

        const response = await fetch(requestURL);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setFollowerCount(data?.count);
      } catch (error) {
        console.error("Failed to fetch score history:", error);
      }
    };
    fetchFollowers();
  }, [fid]);

  console.log(props.data[0])

  return (
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className="flex text-center flex-col h-[300px] mt-8 justify-between">
            <div  className="mx-auto flex max-w-xs flex-col">
              <dt className="text-base leading-7 text-gray-400">Engagement Score Today</dt>
              <dd className="order-first text-2xl font-semibold tracking-tight text-white sm:text-5xl">
                {props?.data[0]?.score.toFixed(2) ?? "_"}
              </dd>
            </div>
            <div className="mx-auto flex max-w-xs flex-col ">
              <dt className="text-base leading-7 text-gray-400">15 Days Average Score</dt>
              <dd className="order-first text-2xl font-semibold tracking-tight text-white sm:text-5xl"> 
              {props?.data[0]?.average.toFixed(2) ?? "_"}
              </dd>
            </div>
            <div  className="mx-auto flex max-w-xs flex-col ">
              <dt className="text-base leading-7 text-gray-400">Followers Count</dt>
              <dd className="order-first text-2xl font-semibold tracking-tight text-white sm:text-5xl">{followerCount.toLocaleString()}</dd>
            </div>
        </dl>
    </div>
  )
}