import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useParams } from "react-router-dom";
import { makeApiUrl } from "../utils/common";
import { imgurSafe } from "../utils/common";

export default function Recommended() {
  const [followers, setFollowers] = useState([]);
  const [ordering, setOrdering] = useState("desc");
  const { fid } = useParams();

  useEffect(() => {
    const fetchFollowers = async () => {
      try {
        const requestURL = makeApiUrl(`recommended/${fid}?order=${ordering}`);

        const response = await fetch(requestURL);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setFollowers(data);
      } catch (error) {
        console.error("Failed to fetch score history:", error);
      }
    };
    fetchFollowers();
  }, [fid, ordering]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flow-root">
        <div className="text-gray-300 py-3 text-sm">
          They follow you but you don't follow them and they are quite engaging.
        </div>
        {!followers || followers.length === 0 ? (
          <div className="text-white text-center">Loading...</div>
        ) : (
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-700">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                    >
                      User
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-white sm:pl-0"
                    >
                      FID
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-center cursor-pointer underline text-sm font-semibold text-white"
                      onClick={(e) => {
                        e.preventDefault();
                        const newOrder = ordering === "desc" ? "asc" : "desc";
                        setFollowers([]);
                        setOrdering(newOrder);
                      }}
                    >
                      Average Engagement Score {ordering === "desc" ? "↓" : "↑"}
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                    >
                      Profile
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800">
                  {followers.map((person) => (
                    <tr key={person.fid}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 cursor-pointer text-sm font-medium text-white sm:pl-0">
                        <Link to={`/stats/${person.fid}`}>
                          <img
                            className="inline-block h-12 w-12 rounded-full"
                            src={imgurSafe(person.pfpURL)}
                            alt=""
                          />
                          <span className="ml-3 underline ">
                            {person.username}
                          </span>
                        </Link>
                      </td>
                      <td className="whitespace-nowrap text-center py-4 text-sm text-gray-300">
                        {person.fid}
                      </td>
                      <td className="whitespace-nowrap  text-center py-4 text-sm text-gray-300">
                        {person.score.toFixed(2)}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-0">
                        <a
                          target="_blank"
                          href={`https://warpcast.com/${person.username}`}
                          className="text-indigo-400 hover:text-indigo-300"
                        >
                          Profile &#x2197;
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
