import { Routes, Route, Link, useParams } from "react-router-dom";
import Stats from "./components/Stats";
import Layout from "./components/Layout";
import Followers from "./components/Followers";
import Following from "./components/Following";
import Landing from "./components/Landing";

export default function App() {
  return (
    <div>
      <Routes>
        {/* <Route index element={<Landing />} />
        <Route path="/" element={<Layout />}> */}
        <Route path="/farstats" element={<Landing />}/>
        <Route path="/" element={<Landing />}/>
        <Route path="/stats/:fid" element={<Stats />}/>
        {/* <Route path=":fid/followers" element={<Followers />} />
        <Route path=":fid/following" element={<Following />} /> */}
        <Route path="*" element={<NoMatch />} />
        {/* </Route> */}
      </Routes>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
